import React from "react"
import styled from "styled-components"
import { FiGlobe, FiPhone } from "react-icons/fi"

const DealerStyles = styled.div`
  width: 350px;
  max-width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;

  h3 {
    font-family: "united-stencil", Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }

  a {
    color: #99c83d;
  }

  .contact-links {
    margin-top: 10px;
    a {
      font-size: 1.5em;
      margin-right: 10px;
    }
  }
`

export default function Dealer({ dealer }) {
  const website = dealer.childMdx?.frontmatter?.website?.startsWith("http")
    ? dealer.childMdx.frontmatter.website
    : `https://${dealer.childMdx.frontmatter.website}`
  return (
    <DealerStyles>
      <h3>{dealer.childMdx.frontmatter.name}</h3>
      <p>{dealer.childMdx.frontmatter.address}</p>
      <p>
        {dealer.childMdx.frontmatter.city}, {dealer.childMdx.frontmatter.state}{" "}
        {dealer.childMdx.frontmatter.zip !== 0 &&
          dealer.childMdx.frontmatter.zip}
      </p>
      <div className="contact-links">
        {dealer.childMdx.frontmatter.phone && (
          <a href={`tel:${dealer.childMdx.frontmatter.phone}`}>
            <FiPhone />
          </a>
        )}
        {dealer.childMdx.frontmatter.website && (
          <a href={website}>
            <FiGlobe />
          </a>
        )}
      </div>
    </DealerStyles>
  )
}
