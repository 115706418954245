import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Dealer from "../components/dealer"

const DealersPageStyles = styled.div`
  background: #e8e8e8;
  min-height: calc(100vh - 170px);
`

const DealerListStyles = styled.div`
  .dealer-inquiry {
    margin: 5em 0;
    text-align: center;

    a {
      color: #99c83d;
    }
  }

  .country-section {
    .country {
      padding: 0.5rem;
      text-align: center;
      font-family: "hand-of-sean", Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: 2rem;
    }

    .dealers {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 3rem;
    }
  }
`

export default function DealersPage({ data }) {
  const siteTitle = "Dealers"
  const page = data.page.childMdx.frontmatter
  const heroImage = getImage(page.bannerImage)
  const dealers = data.dealers.nodes
  let countryGroups = []
  for (const country of [
    ...new Set(dealers.map((d) => d.childMdx.frontmatter.country)),
  ]) {
    countryGroups.push(
      dealers.filter((d) => d.childMdx.frontmatter.country === country)
    )
  }
  // Need to sort out United States by state name
  const unitedStates = countryGroups.find(
    (d) => d[0].childMdx.frontmatter.country === "United States"
  )
  unitedStates.sort((a, b) =>
    a.childMdx.frontmatter.state > b.childMdx.frontmatter.state ? 1 : -1
  )

  const states = unitedStates.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    ;(result[currentValue.childMdx.frontmatter.state] =
      result[currentValue.childMdx.frontmatter.state] || []).push(currentValue)
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result
  }, {})
  countryGroups = countryGroups.filter(
    (c) => c[0].childMdx.frontmatter.country !== "United States"
  )

  const stateDealers = []

  for (const [state, dealers] of Object.entries(states)) {
    stateDealers.push(
      <div key={state} className="country-section">
        <p className="country">{state}, United States</p>
        <div className="dealers">
          {dealers.map((dealer, index) => (
            <Dealer key={index} dealer={dealer} />
          ))}
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <SEO
        title={siteTitle}
        keywords={[
          `carbon wheels`,
          `carbon fiber wheels`,
          `carbon bicycle wheels`,
          `high performance bicycle wheels`,
          `bike wheels`,
          `bmx cruiser wheels`,
          `29er bmx`,
          `fixed gear wheels`,
          `fixed wheels`,
          `fixie wheels`,
          `track wheels`,
          `road bike wheels`,
          `carbon wheelset`,
          `usa made wheels`,
        ]}
      />
      <DealersPageStyles>
        <Hero image={heroImage} title={page.header}>
          <h1>{page.header}</h1>
          {/* <CallToAction text={`Register Now`} link={`dealers/register`} /> */}
        </Hero>
        <DealerListStyles>
          <h2 className="dealer-inquiry">
            Interested in selling our wheels? Fill out a{" "}
            <Link to="/contact">Dealer Inquiry</Link>!
          </h2>
          {stateDealers}
          {countryGroups.map((country, index) => (
            <div key={index} className="country-section">
              <p className="country">
                {country[0].childMdx.frontmatter.country}
              </p>
              <div className="dealers">
                {country.map((dealer, index) => (
                  <Dealer key={index} dealer={dealer} />
                ))}
              </div>
            </div>
          ))}
        </DealerListStyles>
      </DealersPageStyles>
    </Layout>
  )
}

export const query = graphql`
  query DealerPageQuery {
    page: file(name: { eq: "dealers" }) {
      name
      childMdx {
        id
        frontmatter {
          templateKey
          header
          ctaText
          bannerImage {
            name
            childImageSharp {
              gatsbyImageData(quality: 90, height: 500, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    dealers: allFile(filter: { sourceInstanceName: { eq: "dealers" } }) {
      nodes {
        name
        childMdx {
          id
          frontmatter {
            path
            name
            address
            city
            state
            zip
            country
            phone
            website
          }
        }
      }
    }
  }
`
