import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroStyles = styled.div`
  background: black;
  height: calc(100vh - 90px);
  display: grid;

  .gatsby-image-wrapper {
    filter: brightness(50%);
  }

  .hero-content {
    height: 100%;
    display: grid;
    grid-area: 1/1/auto/auto;
    position: relative;
    place-items: baseline;
    align-content: center;
    margin: 0 4em;

    h1 {
      margin: 0;
      color: #fff;
    }

    p {
      color: #fff;
      font-weight: 100;
      margin: 0 0 1em;
      font-size: 1.2em;
    }
  }
`

export default function Hero(props) {
  const image = getImage(props.image)
  return (
    <HeroStyles className={props.className}>
      <GatsbyImage 
        style={{
          gridArea: "1/1",
        }}
        image={image}
        alt={props.title}
      />
      <div className="hero-content">
        {props.children}
      </div>
    </HeroStyles>
  )
}